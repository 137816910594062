import { ITEMS_PER_PAGE } from './constants';

export const selectShows = state => state.shows;
export const selectActive = state => selectShows(state).active;

export const selectAll = state => selectShows(state).all;

export const selectShowBySlug = (state, slug) => selectAll(state)[slug];

export const selectCurrent = state => selectShows(state).current;
export const selectHasInitial = state => selectShows(state).hasInitial;
export const selectArchived = state => selectShows(state).archived;

export const selectFilter = state => selectShows(state).filter;
export const selectSort = state => selectShows(state).sort;
export const selectPage = state => selectShows(state).page;
export const selectHasMorePages = state => {
    const page = selectPage(state);
    const currentItems = selectCurrent(state).length;
    const totalItems = selectArchived(state).length;

    return (
        currentItems % ITEMS_PER_PAGE === 0 &&
        totalItems > ITEMS_PER_PAGE * (page + 1)
    );
};

export const selectTags = state => selectShows(state).tags;
