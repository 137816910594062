import React, { useState, useEffect, useCallback, createRef } from 'react';
import { useSelector } from 'react-redux';

import { MD_BREAKPOINT } from '@layouts/grid';

import { formatDate, formatDateMobile } from '@src/helpers';
import { useMedia } from '@src/helpers/hooks';
import ShowDetails from './details';
import { selectShowBySlug } from '../../store/shows/selectors';
import {
    StyledShowDate,
    StyledShowItem,
    StyledShowTitle,
    ItemWrapper,
    StyledDetailsWrapper,
} from './item.styled';
import { useLazy } from '../../helpers/hooks';

const breakPointsQueries = [
    `(min-width: ${MD_BREAKPOINT}px)`,
    '(min-width: 0)',
];
const breakPointsNames = ['desktop', 'mobile'];

const ShowItem = ({ slug, isActive, handleClick, i }) => {
    const basePath = '/archive';
    const item = useSelector(s => selectShowBySlug(s, slug));

    const currentBP = useMedia(breakPointsQueries, breakPointsNames, 'desktop');
    const formatDateFn =
        currentBP === 'desktop' ? formatDate : formatDateMobile;

    const [animateStart, setAnimateStart] = useState(true);
    const [showDetails, setShowDetails] = useState(false);
    const [isOpening, setIsOpening] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const ref = createRef();
    const loaded = useLazy(ref);
    useEffect(() => {
        if (loaded) {
            setAnimateStart(false);
        }
    }, [loaded]);

    /** Add some more logic to make the transition smoother */
    const handleToggleShowDetails = useCallback(() => {
        setShowDetails(!showDetails);
        setIsOpening(true);
        if (showDetails) {
            setIsClosing(true);
            setTimeout(() => {
                setIsOpening(false);
            }, 20);
        }

        setTimeout(() => {
            setIsOpening(false);
            setIsClosing(false);
        }, 750);
    }, [showDetails]);

    /** Make sure the content is visible during the transition */
    useEffect(() => {
        if (isActive !== showDetails) {
            handleToggleShowDetails();
        }
    }, [isActive, showDetails, handleToggleShowDetails]);

    if (!item) {
        return null;
    }

    const { title, date_start } = item;
    return (
        <ItemWrapper animateStart={animateStart} index={i} ref={ref}>
            <a
                href={isActive ? basePath : `${basePath}/${slug}`}
                onClick={e => handleClick(e, slug, isActive)}
            >
                <StyledShowItem>
                    <StyledShowTitle isActive={isActive}>
                        <span>{title.length ? title[0].text : ''}</span>
                        <div />
                    </StyledShowTitle>
                    <StyledShowDate isActive={isActive}>
                        {formatDateFn(date_start)}
                    </StyledShowDate>
                </StyledShowItem>
            </a>
            <StyledDetailsWrapper
                showDetails={showDetails}
                isOpening={isOpening}
                isClosing={isClosing}
            >
                {showDetails | isOpening | isClosing ? (
                    <ShowDetails item={item} />
                ) : null}
            </StyledDetailsWrapper>
        </ItemWrapper>
    );
};

export default ShowItem;
