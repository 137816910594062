import React, { useEffect, createRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import colors from '@shared/colors';
import SearchSort from '@components/search-sort';
import ShowsList from '@components/shows-list';
import TagsController from '@components/tags/controller';
import { selectCurrent, selectHasMorePages } from '../store/shows/selectors';
import {
    SeedInitialShows,
    ShouldFetchArchivedShows,
    LoadMoreItems,
} from '../store/shows/actions';
import RecentlyViewed from '../components/recently-viewed';

import {
    ArchiveContent,
    ArchiveFiltersInner,
    ArchiveFiltersWrapper,
    ArchiveShowsWrapper,
    ArchiveWrapper,
    StyledLoadMoreOuter,
    LoadMoreButton,
    ArchivePlaceholder,
} from './archive.styled';
import { useLazy } from '../helpers/hooks';

const Archive = props => {
    if (!props.pageContext) {
        return null;
    }

    return <ArchiveDispatcher {...props} />;
};

const ArchiveDispatcher = props => {
    const { uid: activeSlug } = props.pageContext;

    const dispatch = useDispatch();
    dispatch(SeedInitialShows(props.pageContext.archivedShows, activeSlug));

    useEffect(() => {
        dispatch(ShouldFetchArchivedShows());
    }, [dispatch]);

    return <ArchivePage {...props} />;
};

const LoadMoreWrapper = () => {
    const dispatch = useDispatch();
    const hasMorePages = useSelector(selectHasMorePages);
    const ref = createRef();
    const loaded = useLazy(ref);
    const [animateStart, setAnimateStart] = useState(true);

    useEffect(() => {
        if (loaded) {
            setAnimateStart(false);
        }
    }, [loaded, hasMorePages]);

    return (
        <StyledLoadMoreOuter ref={ref}>
            {hasMorePages ? (
                <LoadMoreButton
                    onClick={() => dispatch(LoadMoreItems())}
                    animateStart={animateStart}
                >
                    load more ↓
                </LoadMoreButton>
            ) : null}
        </StyledLoadMoreOuter>
    );
};

const ArchivePage = ({ pageContext }) => {
    const currentShows = useSelector(selectCurrent);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, 500);
    }, []);

    return loaded ? (
        <ArchiveWrapper>
            <SearchSort
                bgColor={colors.pineGreen}
                mainColumn="Artists"
                secondColumn="Date"
            />
            <ArchiveContent>
                <ArchiveShowsWrapper>
                    <ShowsList shows={currentShows} />
                </ArchiveShowsWrapper>
                <ArchiveFiltersWrapper>
                    <ArchiveFiltersInner>
                        <TagsController />
                    </ArchiveFiltersInner>
                </ArchiveFiltersWrapper>
            </ArchiveContent>
            <LoadMoreWrapper />
            <RecentlyViewed />
        </ArchiveWrapper>
    ) : (
        <ArchivePlaceholder hasActive={pageContext.uid !== 'archive'} />
    );
};

export default Archive;
