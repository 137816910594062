import styled from 'styled-components';
import { generateVwPercentageWidth } from '@src/helpers';
import { generateBreakpoint } from '@layouts/grid';

export const StyledSideMenu = styled.div`
    position: absolute;
    overflow: hidden;

    pointer-events: all;

    display: grid;
    grid-template-columns: 26px 1fr;
    
    z-index: 10;
    height: 162px;
    right: 0;
    bottom: 0;

    transition: width 550ms cubic-bezier(0.645, 0.455, 0.355, 1.005);

    ${props =>
        props.isOpen
            ? `
            width: ${props.maxWidth > 320 ? 320 : props.maxWidth || 240}px;
    ${generateBreakpoint('lg')(`width: ${props.maxWidth || '240'}px;`)}`
            : `width: 26px;

    ${generateBreakpoint('lg')(`width: 40px;`)}`}

    ${generateBreakpoint('lg')(
        `grid-template-columns: 40px 1fr;
        height: 200px;`,
    )}
    ${props =>
        !props.isOpen
            ? `${generateBreakpoint('lg')('width: 40px')}`
            : `${generateBreakpoint('lg')(
                  `${generateVwPercentageWidth(
                      props.maxWidth || 240,
                      1.5 * (props.maxWidth || 240),
                  )}`,
              )}
        `}
`;
