import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectFilter, selectSort } from '../../store/shows/selectors';
import {
    ShouldUpdateOrder,
    ShouldUpdateFilter,
} from '../../store/shows/actions';
import {
    ORDER_ASC,
    ORDER_DESC,
    SORT_TYPE_TITLE,
    SORT_TYPE_DATE,
} from '../../store/shows/constants';

import {
    StyledSearchSort,
    TitleBar,
    DateBar,
    SearchBar,
    SortElement,
} from './index.styled';

const SearchSort = ({
    mainColumn = 'Artists',
    secondColumn = 'Date',
    bgColor,
}) => {
    const dispatch = useDispatch();
    const filter = useSelector(selectFilter);
    const order = useSelector(selectSort);
    const handleSearch = e => {
        dispatch(
            ShouldUpdateFilter({
                query: e.target.value,
            }),
        );
    };

    return (
        <StyledSearchSort bgColor={bgColor}>
            <TitleBar
                onClick={() => {
                    dispatch(
                        ShouldUpdateOrder({
                            type: SORT_TYPE_TITLE,
                            direction:
                                order.direction === ORDER_ASC
                                    ? ORDER_DESC
                                    : ORDER_ASC,
                        }),
                    );
                }}
            >
                <SortElement
                    isActive={order.type === SORT_TYPE_TITLE}
                    isDesc={order.direction === ORDER_DESC}
                >
                    <span>{mainColumn}</span>
                    <div />
                </SortElement>
            </TitleBar>
            <DateBar
                onClick={() => {
                    dispatch(
                        ShouldUpdateOrder({
                            type: SORT_TYPE_DATE,
                            direction:
                                order.direction === ORDER_ASC
                                    ? ORDER_DESC
                                    : ORDER_ASC,
                        }),
                    );
                }}
            >
                <SortElement
                    isActive={order.type === SORT_TYPE_DATE}
                    isDesc={order.direction === ORDER_DESC}
                >
                    <span>{secondColumn}</span>
                    <div />
                </SortElement>
            </DateBar>
            <SearchBar>
                <input
                    type="text"
                    placeholder="Search…"
                    value={filter.query}
                    onChange={handleSearch}
                />
            </SearchBar>
        </StyledSearchSort>
    );
};

export default SearchSort;
