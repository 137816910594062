import React, { useState } from 'react';
import ShowDetailsInfo from '../show-details-info';
import Embed from '../embed';

import {
    DesktopEmbed,
    MobileEmbed,
    StyledShowDetail,
    StyledShowDetailEmbed,
    StyledDetailsInfoWrapper,
    StyledToggleWrapper,
    ToggleItem,
} from './details.styled';

const toggleItems = ['video', 'audio'];

const ToggleWrapper = ({ source, setSource }) => {
    return (
        <StyledToggleWrapper>
            {toggleItems.map(i => (
                <ToggleItem
                    key={`toggle_${i}`}
                    onClick={() => setSource(i)}
                    isActive={source === i}
                >
                    {i}
                </ToggleItem>
            ))}
        </StyledToggleWrapper>
    );
};

const ShowDetailsRender = ({ item }) => {
    const { description, audio_url, video_url, social_links, _meta, open_in_new_tab: openInNewTab } = item;

    const [source, setSource] = useState(video_url ? 'video' : 'audio');
    const hasToggle = !!video_url && !!audio_url;
    return (
        <StyledShowDetail>
            <StyledShowDetailEmbed>
                <MobileEmbed>
                    <Embed
                        targetWidth={320}
                        maxRatio={2.33}
                        embedHeight={197}
                        embedWidth={296}
                        openInNewTab={source === 'video' ? openInNewTab: false}
                        url={source === 'video' ? video_url : audio_url}
                    />
                </MobileEmbed>
                <DesktopEmbed>
                    <Embed
                        embedHeight={389}
                        embedWidth={692}
                        openInNewTab={source === 'video' ? openInNewTab: false}
                        url={source === 'video' ? video_url : audio_url}
                    />
                </DesktopEmbed>
            </StyledShowDetailEmbed>
            <StyledDetailsInfoWrapper>
                {hasToggle ? (
                    <ToggleWrapper source={source} setSource={setSource} />
                ) : null}
                <ShowDetailsInfo
                    description={description}
                    social_links={social_links}
                    _meta={_meta}
                />
            </StyledDetailsInfoWrapper>
        </StyledShowDetail>
    );
};

const ShowDetails = ({ item }) => {
    if (!item) {
        return null;
    }

    return <ShowDetailsRender item={item} />;
};

export default ShowDetails;
