import React from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { StyledItem } from './item.styled';
import { selectShowBySlug, selectActive } from '../../store/shows/selectors';
import { UpdateActiveSlug } from '../../store/shows/actions';

const handleClick = (e, dispatch, activeSlug, slug) => {
    e.preventDefault();
    if (slug !== activeSlug) {
        dispatch(UpdateActiveSlug(slug));
    }
};

const RecentItem = ({ slug }) => {
    const dispatch = useDispatch();
    const item = useSelector(s => selectShowBySlug(s, slug));
    const activeSlug = useSelector(selectActive);

    if (!item) {
        return null;
    }

    const label = get(item, 'title[0].text', '');

    return (
        <StyledItem>
            <a
                href={`/archive/${slug}`}
                onClick={e => handleClick(e, dispatch, activeSlug, slug)}
            >
                {label}
            </a>
        </StyledItem>
    );
};

export default RecentItem;
