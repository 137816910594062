import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ShowItem from './item';
import { UpdateActiveSlug } from '../../store/shows/actions';
import { selectActive } from '../../store/shows/selectors';

const ShowsListWrapper = styled.div`
    padding-bottom: 30px;
`;

const handleClick = (e, dispatch, slug) => {
    e.preventDefault();

    dispatch(UpdateActiveSlug(slug));
};

const ShowsList = ({ shows = [] }) => {
    const dispatch = useDispatch();
    const activeSlug = useSelector(selectActive);
    return (
        <ShowsListWrapper>
            {shows.map((item, i) => (
                <ShowItem
                    key={`${item}_show_item`}
                    i={i}
                    slug={item}
                    isActive={item === activeSlug}
                    handleClick={(e, slug) => handleClick(e, dispatch, slug)}
                />
            ))}
        </ShowsListWrapper>
    );
};

export default ShowsList;
