import styled from 'styled-components';
import { generateGrid, generateCol, generateBreakpoint } from '@layouts/grid';
import colors from '@shared/colors';
import searchIcon from '@src/images/icons/archive-search-icon.svg';
import sortDownIcon from '@src/images/icons/archive-sort-down.svg';

export const StyledSearchSort = styled.div`
    ${generateGrid()}
    position: fixed;
    top: 58px;
    width: 100%;

    border-bottom: 1px solid ${colors.brownGrey};

    color: ${colors.white};
    background-color: rgba(14, 76, 46, 0.9);
    z-index: 10;

    font-size: 12px;
    font-weight: 300;
    line-height: 1.17;
    text-transform: uppercase;

    ${generateBreakpoint('md')(
        `top: 82px;
        font-size: 14px;
        line-height: 1.29;
        letter-spacing: 0.28px;`,
    )}
`;

export const SortElement = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'SuisseIntl';

    padding-top: 12px;
    padding-bottom: 12px;
    cursor: pointer;

    ${generateBreakpoint('md')(
        `padding-top: 19px;
        padding-bottom: 19px;`,
    )}

    > div {
        display: ${props => (props.isActive ? 'block' : 'none')};
        margin-left: 10px;
        height: 6px;
        width: 14px;
        background-image: url(${sortDownIcon});
        background-repeat: no-repeat;
        background-size: contain;
        transition: transform 0.2s ease;

        transform: scaleY(${props => (props.isDesc ? '1' : '-1')});

        ${generateBreakpoint('md')(
            `height: 7px;
            width: 16px;
            top: 22px`,
        )}
    }
`;

export const TitleBar = styled.div`
    padding-left: 12px;
    order: 2;

    ${generateCol(8)}
    ${generateBreakpoint('md')(
        `${generateCol(6)}
        order: 1;
        padding-left: 20px;`,
    )}
`;

export const DateBar = styled.div`
    ${generateCol(4)}
    order: 3;
    justify-self: flex-end;
    padding-right: 12px;
    ${generateBreakpoint('md')(
        `${generateCol(3)}
        order: 2;
        justify-self: flex-start;
        padding-right: 0px;`,
    )}
`;

export const SearchBar = styled.div`
    position: relative;
    ${generateCol(12)}
    order: 1;

    padding-left: 12px;
    padding-right: 12px;

    padding-top: 12px;
    padding-bottom: 12px;
    font-family: 'SuisseIntl';
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    ${generateBreakpoint('md')(
        `padding-top: 19px;
        ${generateCol(3)}
    padding-bottom: 19px;
    padding-right: 20px;
    padding-left: 25px;
    order: 3;

    border-bottom: 0;
    border-left: 1px solid rgba(255, 255, 255, 0.5);`,
    )}

    input[type='text'] {
        padding-bottom: 0;
        border-bottom: 0;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.17;
        padding: 0;

        ${generateBreakpoint('md')(
            `font-size: 15px;
            font-weight: 300;
            line-height: 1.2;`,
        )}

        color: ${colors.white};

        ::placeholder {
            color: ${colors.white};
        }
    }

    ::after {
        display: block;
        content: '';

        position: absolute;
        right: 12px;
        top: 12px;

        height: 14px;
        width: 14px;
        background-image: url(${searchIcon});
        background-repeat: no-repeat;
        background-size: contain;

        ${generateBreakpoint('md')(
            `height: 19px;
            width: 19px;
            top: 20px`,
        )}
    }
`;
