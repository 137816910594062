import styled from 'styled-components';
import colors from '@shared/colors';
import { generateBreakpoint } from '../../layouts/grid';

export const StyledItem = styled.div`
    position: relative;
    z-index: 20;
    font-family: 'SuisseIntl';

    padding: 0 8px;

    &,
    a,
    a:visited,
    a:active {
        font-size: 12px;
        line-height: 2.7;
        letter-spacing: 0.28px;
        text-transform: uppercase;
        color: ${colors.pineGreen};
    }

    ${generateBreakpoint('lg')(
        `padding: 0 13px;

        &,
        a,
        a:visited,
        a:active {
            font-size: 14px;
            line-height: 2.86;
            letter-spacing: 0.28px;
        }
        `,
    )}

    border-bottom: 1px solid ${colors.mauve};

    max-width: 240px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    :last-child {
        border-bottom: none;
    }
`;
