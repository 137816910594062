import axios from 'axios';
import { navigate } from '@reach/router';
import { normalize } from 'normalizr';
import { get, has, intersection, isEqual } from 'lodash';
import parseISO from 'date-fns/parseISO';
import compareAsc from 'date-fns/compareAsc';
import compareDesc from 'date-fns/compareDesc';

import {
    selectArchived,
    selectSort,
    selectFilter,
    selectAll,
    selectActive,
    selectPage,
    selectCurrent,
} from './selectors';
import {
    FETCH_ARCHIVED,
    SEED_INITIAL,
    UPDATE_CURRENT,
    UPDATE_ACTIVE,
    ORDER_ASC,
    showSchema,
    UPDATE_SORT,
    SORT_TYPE_DATE,
    SORT_TYPE_TITLE,
    UPDATE_FILTER,
    UPDATE_PAGE,
    ITEMS_PER_PAGE,
} from './constants';
import { UpdateRecentlyViewed } from '../recently-viewed/actions';

const UpdateCurrentShows = () => (dispatch, getState) => {
    const order = selectSort(getState());
    const filter = selectFilter(getState());
    const allShows = selectAll(getState());
    const page = selectPage(getState());

    let resultShows = Object.keys(allShows).map(s => allShows[s]);

    if (filter && filter.query) {
        resultShows = resultShows.filter(
            s =>
                has(s, 'title[0].text') &&
                get(s, 'title[0].text', '')
                    .toLocaleLowerCase()
                    .includes(filter.query.toLocaleLowerCase()),
        );
    }
    if (filter && filter.tags.length) {
        resultShows = resultShows.filter(s => {
            const showTags = get(s, '_meta.tags', []);
            const activeFilters = filter.tags;
            const commonTags = intersection(showTags, activeFilters);
            return commonTags.length === activeFilters.length;
        });
    }
    if (order.type === SORT_TYPE_DATE) {
        const dateSortFn =
            order.direction === ORDER_ASC ? compareAsc : compareDesc;
        resultShows = resultShows.sort((a, b) =>
            dateSortFn(parseISO(a.date_start), parseISO(b.date_start)),
        );
    } else if (order.type === SORT_TYPE_TITLE) {
        const titleSortFn =
            order.direction === ORDER_ASC
                ? (a, b) => a.localeCompare(b)
                : (a, b) => b.localeCompare(a);
        resultShows = resultShows.sort((a, b) =>
            titleSortFn(get(a, 'title[0].text'), get(b, 'title[0].text')),
        );
    }

    return dispatch({
        type: UPDATE_CURRENT,
        payload: resultShows
            .map(i => i.uid)
            .slice(0, (1 + page) * ITEMS_PER_PAGE),
    });
};

export const ShouldUpdateOrder = order => (dispatch, getState) => {
    const currentOrder = selectSort(getState());

    if (isEqual(currentOrder, order)) {
        return;
    }

    dispatch(UpdatePage(0));

    dispatch({
        type: UPDATE_SORT,
        payload: order,
    });

    setTimeout(() => {
        dispatch(UpdateCurrentShows());
    });
};

export const ShouldUpdateFilter = filter => (dispatch, getState) => {
    const currentFilter = selectFilter(getState());

    if (isEqual(currentFilter, filter)) {
        return;
    }

    dispatch(UpdatePage(0));

    dispatch({
        type: UPDATE_FILTER,
        payload: {
            ...currentFilter,
            ...filter,
        },
    });

    setTimeout(() => {
        dispatch(UpdateCurrentShows());
    });
};

export const LoadMoreItems = () => (dispatch, getState) => {
    const page = selectPage(getState());

    dispatch(UpdatePage(page + 1));

    setTimeout(() => {
        dispatch(UpdateCurrentShows());
    });
};

export const UpdatePage = page => {
    return {
        type: UPDATE_PAGE,
        payload: page,
    };
};

export const UpdateActiveSlug = (slug, force = false) => (
    dispatch,
    getState,
) => {
    const currentSlug = selectActive(getState());
    const currentShows = selectCurrent(getState());

    const newSlug = slug === currentSlug ? '' : slug;
    const basePath = '/archive';

    const needsRefresh =
        !!newSlug &&
        newSlug !== 'archive' &&
        currentShows.indexOf(newSlug) === -1;

    setTimeout(
        () => {
            navigate(!newSlug ? basePath : `${basePath}/${slug}`, {
                state: {
                    noScroll: true,
                    noRefresh: !needsRefresh,
                },
            });
        },
        needsRefresh ? 0 : 500,
    );

    if (newSlug) {
        dispatch(UpdateRecentlyViewed(newSlug));
    }

    return dispatch({
        type: UPDATE_ACTIVE,
        payload: newSlug,
    });
};

export const SeedInitialShows = (shows, activeSlug) => {
    return {
        type: SEED_INITIAL,
        payload: {
            shows: normalize(shows, [showSchema]),
            activeSlug,
        },
    };
};

const FetchArchivedShows = () => ({
    type: FETCH_ARCHIVED,
    payload: axios
        .get('/page-data/archive-data/page-data.json')
        .then(({ data: { result: { pageContext: { archive } } } }) =>
            normalize(archive, [showSchema]),
        )
        .then(d => ({
            ...d,
            entities: {
                ...d.entities,
                tag: Object.keys(d.entities.tag),
            },
        })),
});

export const ShouldFetchArchivedShows = () => (dispatch, getState) => {
    const archived = selectArchived(getState());

    if (archived && archived.length) {
        return;
    }

    return dispatch(FetchArchivedShows());
};
