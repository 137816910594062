import React from 'react';
import {
    StyledEmbed,
    EmbedInner,
    EmbedFallbackImage,
    EmbedFallbackLink,
} from './index.styled';

const HEIGHT_REGEX = /height="\d{1,5}"/;
const WIDTH_REGEX = /width="\d{1,5}"/;

const fixEmbedHeightAndWidth = html => {
    if (!html) return html;

    const fixedDimensions = html
        .replace(HEIGHT_REGEX, 'height="100%"')
        .replace(WIDTH_REGEX, 'width="100%"');
    // TODO: ADD modestbranding=1 with RegExp
    // .replace('src="https://www.youtube.com/embed/FADGMdMR0n4?feature=oembed"', 'src="https://www.youtube.com/embed/FADGMdMR0n4?feature=oembed&modestbranding=1&showinfo=0&rel=0"')

    return fixedDimensions;
};

const Embed = ({
    embedHeight,
    embedWidth,
    targetWidth,
    maxRatio,
    url,
    openInNewTab = false,
}) => {
    return (
        <StyledEmbed
            embedHeight={embedHeight}
            embedWidth={embedWidth}
            targetWidth={targetWidth}
            maxRatio={maxRatio}
            noUrl={!url}
        >
            {url ? (
                openInNewTab ? (
                    <EmbedFallbackLink href={url.embed_url} target="_new">
                        <EmbedFallbackImage
                            src={(url.thumbnail_url || '').replace(
                                'hqdefault',
                                'maxresdefault',
                            )}
                        />
                    </EmbedFallbackLink>
                ) : (
                    <EmbedInner
                        dangerouslySetInnerHTML={{
                            __html: fixEmbedHeightAndWidth(url.html),
                        }}
                    />
                )
            ) : (
                <p>No embed available</p>
            )}
        </StyledEmbed>
    );
};

export default Embed;
