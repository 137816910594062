import { selectRecentlyViewed } from './selectors';
import { UPDATE_RECENTLY_VIEWED, UPDATE_ISOPEN } from './constants';
import { selectIsOpen } from './selectors';

export const UpdateRecentlyViewed = slug => (dispatch, getState) => {
    const existingViewed = selectRecentlyViewed(getState());

    const newViewed = [...existingViewed];
    if (!newViewed.length || newViewed.indexOf(slug) === -1) {
        newViewed.unshift(slug);
    }

    return dispatch({
        type: UPDATE_RECENTLY_VIEWED,
        payload: newViewed.slice(0, 5),
    });
};

export const ToggleIsOpen = () => (dispatch, getState) => {
    const isOpen = selectIsOpen(getState());

    dispatch({
        type: UPDATE_ISOPEN,
        payload: !isOpen,
    });
};
