import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectRecentlyViewed,
    selectIsOpen,
} from '@src/store/recently-viewed/selectors';
import RecentItem from './item';
import { ToggleIsOpen } from '../../store/recently-viewed/actions';
import SideMenu from '../side-menu';
import {
    ItemsWrapper,
    StickyWrapper,
    StickyInnerWrapper,
} from './index.styled';

const RecentlyViewed = () => {
    const dispatch = useDispatch();
    const items = useSelector(selectRecentlyViewed);
    const isOpen = useSelector(selectIsOpen);
    const buttonProps = {
        text: 'recently viewed',
        onClick: () => {
            dispatch(ToggleIsOpen());
        },
    };
    return (
        <StickyWrapper>
            <StickyInnerWrapper>
                <SideMenu buttonProps={buttonProps} isOpen={isOpen}>
                    <ItemsWrapper>
                        {items && items.length
                            ? items.map(i => (
                                  <RecentItem key={`recent_${i}`} slug={i} />
                              ))
                            : null}
                    </ItemsWrapper>
                </SideMenu>
            </StickyInnerWrapper>
        </StickyWrapper>
    );
};

export default RecentlyViewed;
