import styled from 'styled-components';
import colors from '@shared/colors';
import { generateContainer, generateGrid, generateCol } from '@layouts/grid';
import { generateBreakpoint } from '../layouts/grid';
import { buttonHover } from '../shared/forms';

export const ArchivePlaceholder = styled.div`
    height: ${props => (props.hasActive ? '100vw' : '894px')};
    width: 100vw;
    max-width: 100%;
`;

export const ArchiveWrapper = styled.div`
    padding-top: 79px;
    padding-bottom: 60px;

    ${generateBreakpoint('md')(
        `padding-top: 57px;
        padding-bottom: 90px;`,
    )}
`;

export const StyledLoadMoreOuter = styled.div`
    display: flex;
    justify-content: center;
`;

export const LoadMoreButton = styled.div`
    background-color: transparent;
    display: inline-block;
    padding: 12px 20px;
    text-transform: uppercase;

    font-family: 'SuisseIntl';
    z-index: 2;

    font-size: 12px;
    line-height: 1.21;
    letter-spacing: 1px;
    color: ${colors.white};
    border: 1px solid ${colors.white};

    cursor: pointer;

    ${generateBreakpoint('md')('font-size: 14px;')}

    opacity: 1;
    transition: opacity 1000ms cubic-bezier(0, 0.5, 0.8, 1) 750ms;

    ${props => (props.animateStart ? `opacity: 0;` : '')}

    ${buttonHover(colors.white, colors.pineGreen)}
`;

export const ArchiveContent = styled.div`
    ${generateContainer()}
    ${generateGrid()}
    padding-top: 14px;
    padding-bottom: 0px;
    min-height: calc(50vh);
`;

export const ArchiveShowsWrapper = styled.div`
    ${generateCol(12)}

    ${generateBreakpoint('lg')(generateCol(9))}
`;

export const ArchiveFiltersWrapper = styled.div`
    display: none;

    ${generateBreakpoint('lg')(
        `display: block;
        ${generateCol(3)}
        justify-self: end;`,
    )}
`;

export const ArchiveFiltersInner = styled.div`
    max-width: 100%;
    margin-top: 5px;
    margin-left: auto;
    text-align: right;
`;
