import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import colors from '@shared/colors';
import Tags from '.';
import { selectFilter, selectTags } from '../../store/shows/selectors';
import { ShouldUpdateFilter } from '../../store/shows/actions';

const StyledTagsController = styled.div`
    div > div {
        cursor: pointer;
    }
`;

const TagsController = () => {
    const dispatch = useDispatch();
    const filter = useSelector(selectFilter);
    const allTags = useSelector(selectTags);
    const handleClick = tag => {
        const newTags = [...filter.tags];
        const tagIndex = filter.tags.indexOf(tag);
        if (tagIndex === -1) {
            newTags.push(tag);
        } else {
            newTags.splice(tagIndex, 1);
        }
        dispatch(
            ShouldUpdateFilter({
                tags: newTags,
            }),
        );
    };

    return (
        <StyledTagsController>
            <Tags
                color={colors.mauve}
                bgColor={colors.pineGreen}
                selectedColor={colors.mediumPink}
                tags={allTags}
                selectedTags={filter.tags}
                onClick={handleClick}
            />
        </StyledTagsController>
    );
};

export default TagsController;
