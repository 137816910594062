import styled from 'styled-components';
import { generateGrid, generateCol } from '@layouts/grid';
import colors from '@shared/colors';
import { generateBreakpoint } from '../../layouts/grid';

export const MobileEmbed = styled.div`
    display: block;
    ${generateBreakpoint('md')('display: none;')}
`;

export const DesktopEmbed = styled.div`
    display: none;
    ${generateBreakpoint('md')('display: block;')}
`;

export const StyledDetailsInfoWrapper = styled.div`
    ${generateCol(12)}

    ${generateBreakpoint('md')(
        `${generateCol(6)}
        padding: 0;

        > div:last-child {
            padding-top: 12px;
        }`,
    )}

    padding: 14px 0;

    > div:last-child {
        padding-top: 8px;
    }

    ${generateBreakpoint('lg')(`
        ${generateCol(3)}
        max-width: 100%;
        `)}
`;

export const StyledShowDetail = styled.div`
    ${generateGrid()}

    padding-top: 15px;
    padding-bottom: 21px;
    min-height: 400px;

    ${generateBreakpoint('lg')(`${generateGrid(9)}`)}
`;

export const StyledShowDetailEmbed = styled.div`
    position: relative;
    ${generateCol(12)}

    ${generateBreakpoint('md')(generateCol(6))}
`;

export const StyledToggleWrapper = styled.div`
    display: inline-block;
    border-radius: 7px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.8);
`;

export const ToggleItem = styled.div`
    text-transform: uppercase;
    display: inline-block;
    padding: 4px 8px;
    color: black;
    cursor: pointer;
    font-size: 12px;

    ${props =>
        props.isActive
            ? `background-color: ${colors.mediumPink};
        color: white;`
            : ''}
`;
