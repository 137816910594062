import styled from 'styled-components';
import colors from '@shared/colors';
import {
    generateVwPercentageHeight,
    generateVwPercentageWidth,
} from '@src/helpers';

export const StyledEmbed = styled.div`
    display: flex;
    position: relative;
    z-index: 2;

    ${props =>
        props.embedHeight
            ? generateVwPercentageHeight(
                  props.embedHeight,
                  (props.maxRatio || 1) * props.embedHeight,
                  props.targetWidth || 1440,
              )
            : 'height: auto;'}
    ${props =>
        props.embedWidth
            ? generateVwPercentageWidth(
                  props.embedWidth,
                  (props.maxRatio || 1) * props.embedWidth,
                  props.targetWidth || 1440,
              )
            : 'width: 100%;'}
    
    justify-content: center;
    align-items: center;
    color: ${colors.brownGrey};

    background-color: ${props => (props.noUrl ? 'white' : 'transparent')};
`;

export const EmbedInner = styled.div`
    height: 100%;
    width: 100%;
`;

export const EmbedFallbackLink = styled.a`
    display: block;
    height: 100%;
    width: 100%;
`;

export const EmbedFallbackImage = styled.img`
    max-height: 100%;
    max-width: 100%;
`;
