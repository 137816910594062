import styled from 'styled-components';
import colors from '@shared/colors';
import sideMenuDown from '@src/images/icons/side-menu-down.svg';
import { generateBreakpoint } from '../../layouts/grid';

export const StyledButton = styled.div`
    display: inline-block;

    height: 162px;
    ${generateBreakpoint('lg')(`height: 200px;`)}

    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;

    cursor: pointer;

    background-color: ${props => props.bgColor || colors.mauve};

    div {
        position: absolute;
        left: 0;
        bottom: -42px;
        transform-origin: 0 0;
        width: 162px;
        padding: 8px 6px;
        text-align: right;
        transform: rotate(-90deg);

        font-size: 12px;
        font-weight: 500;
  
        line-height: 1.17;
        letter-spacing: 0.24px;

        ${generateBreakpoint('lg')(
            `width: 200px;
            padding: 13px 10px;
            padding-right: 20px;
            
            font-size: 14px;
            line-height: 1.21;
            letter-spacing: 0.28px;`,
        )}

        color: ${props => props.color || colors.pineGreen};

        text-transform: uppercase;

        > span {
            display: inline-block;
            margin-right: 8px;
            width: 16px;
            height: 7px;
            object-fit: contain;
            color: ${props => props.color || colors.pineGreen};
            background-image: url(${props => props.icon || sideMenuDown});
            background-repeat: no-repeat;
            background-size: contain;
            transition: transform .2s ease;
        }
    }

    

    ${props =>
        props.isOpen
            ? ''
            : `div > span {
            transform: scaleY(-1);
        }`}
`;
