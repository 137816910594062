import React from 'react';
import { StyledButton } from './button.styled';

const Button = ({ isOpen, buttonProps }) => {
    if (!buttonProps) {
        return null;
    }
    const { onClick, bgColor, color, text, icon } = buttonProps;

    return (
        <StyledButton
            bgColor={bgColor}
            icon={icon}
            color={color}
            onClick={e => typeof onClick === 'function' && onClick(e)}
            isOpen={isOpen}
        >
            <div>
                <span />
                {text}
            </div>
        </StyledButton>
    );
};

export default Button;
