import React from 'react';
import { StyledSideMenu } from './index.styled';
import Button from './button';

const SideMenu = ({ isOpen, maxWidth, buttonProps, children }) => {
    return (
        <StyledSideMenu isOpen={isOpen} maxWidth={maxWidth}>
            <Button isOpen={isOpen} buttonProps={buttonProps} />
            <div>{children}</div>
        </StyledSideMenu>
    );
};

export default SideMenu;
