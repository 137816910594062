import styled from 'styled-components';
import colors from '@shared/colors';
import { generateBreakpoint } from '@layouts/grid';

export const StickyWrapper = styled.div`
    position: sticky;
    bottom: 60px;
    width: 100vw;
    max-width: 100%;
    z-index: 10;
`;

export const StickyInnerWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    max-width: 100%;
    height: 162px;
    overflow-x: hidden;
    pointer-events: none;

    ${generateBreakpoint('lg')('height: 200px;')}
`;

export const ItemsWrapper = styled.div`
    background-color: ${colors.brownGrey};
    height: 100%;
`;
