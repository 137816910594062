import styled from 'styled-components';
import { generateGrid, generateCol, generateBreakpoint } from '@layouts/grid';
import colors from '@shared/colors';
import archiveActiveDown from '@src/images/icons/schedule-triangle-down-pink.svg';

export const ItemWrapper = styled.div`
    opacity: 1;
    transition: opacity 1000ms cubic-bezier(0, 0.5, 0.8, 1);

    ${props =>
        props.index ? `transition-delay: ${(props.index % 15) * 0.04}s;` : ''}

    ${props => (props.animateStart ? `opacity: 0;` : '')}
`;

export const StyledShowItem = styled.div`
    ${generateGrid(9)}

    transition: color 130ms cubic-bezier(0.410, 0.400, 0.595, 0.995);

    &:hover {
        color: ${colors.mediumPink};
    }
`;

export const StyledShowTitle = styled.div`
    ${generateCol(6)}

    font-size: 20px;
    line-height: 1.2;
    letter-spacing: -0.6px;

    text-transform: uppercase;
    cursor: pointer;

    ${generateBreakpoint('md')(
        `font-size: 40px;
    line-height: 1;
    letter-spacing: -1.2px;

    > div {
        height: 13px;
        width: 28px;
    }`,
    )}

    display: flex;
    justify-content: flex-start;
    align-items: center;

    > span {
        position: relative;

        ::after {
            position: absolute;
            bottom: 1px;
            left: 0;
            content: '';
            height: 2px;
            width: 100%;
            background-color: transparent;
        }
    }

    > div {
        display: none;
        margin-left: 10px;

        width: 20px;
        height: 9px;

        background-image: url(${archiveActiveDown});
        background-repeat: no-repeat;
        background-size: contain;
    }

    ${props =>
        props.isActive
            ? `color: ${colors.mediumPink};
        > span::after {
            background-color: ${colors.mediumPink};
        }
        > div {
            display: block;
        }
        `
            : ''}
`;

export const StyledShowDate = styled.div`
    ${generateCol(3)}

    font-size: 20px;
    line-height: 1.2;
    letter-spacing: -0.6px;
    justify-self: flex-end;

    text-transform: uppercase;

    ${generateBreakpoint('md')(
        `font-size: 40px;
    line-height: 1.1;
    letter-spacing: -1.2px;
    justify-self: flex-start;`,
    )}

    ${props => (props.isActive ? `color: ${colors.mediumPink};` : '')}
`;

export const StyledDetailsWrapper = styled.div`
    overflow: hidden;
    max-height: 0;
    transition: max-height 750ms cubic-bezier(0.645, 0.455, 0.355, 1.005);

    ${({ isOpening }) => (isOpening ? `max-height: 100vh;` : '')}

    ${({ showDetails, isOpening }) =>
        showDetails && !isOpening ? `max-height: initial;` : ''}
`;
